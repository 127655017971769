import React from 'react';

export const DonateConfig = {
  routes: [
    {
      path: '/donate',
      exact: true,
      component: React.lazy(() => import('./')),
    }
  ],
};
