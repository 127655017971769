import React from 'react';

export const UserConfig = {
  routes: [
    {
      path: '/user',
      exact: true,
      component: React.lazy(() => import('.')),
    },
    {
      path: '/user/change-password',
      exact: true,
      component: React.lazy(() => import('./Password')),
    }
  ],
};
