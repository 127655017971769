import React from 'react';

export const OurCentersConfig = {
  routes: [
    {
      path: '/our-centers',
      exact: true,
      component: React.lazy(() => import('./')),
    },  
  ],
};
