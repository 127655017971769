import {SERVER_URL, CLIENT_URL} from '../config/server';

const newsletterAction = (newsletterData) =>{
    fetch(`${SERVER_URL}/home/newsletter`, {
        method: 'POST',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newsletterData)
      }).then(response => response.json())
      .then(json => {
          console.log(json.message, 'json')
          if(json.status === 200){
            localStorage.setItem('login-success', JSON.stringify(json.message));
            window.location.reload();
          }else if(json.status === 201){
            localStorage.setItem('login-error', JSON.stringify(json.message));
              window.location.reload();
          }else{
            localStorage.setItem('login-error', JSON.stringify(json.message));
            window.location.reload();
          }
      });
}

export default newsletterAction
