import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { HiMenu, HiX, HiOutlineChevronDown } from 'react-icons/hi';
import navigation from './menu.json';
import './appbar.css';
import {SERVER_URL, CLIENT_URL} from '../../../config/server';
import logoutAction from '../../../actions/logout';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Appbar() {

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const logoutHandler = logoutAction;
  
  return (
    <Disclosure
      as='nav'
      className='mynav text-darkgreen text-bolder md:bg-opacity-80 bg-opacity-100'
    >
      {({ open }) => (
        <>
          <div className='mx-auto px-6 sm:px-6 lg:px-0'>
            <div className='relative flex items-center justify-evenly md:px-8 h-20'>
              <div className='absolute inset-y-0 left-0 flex items-center md:hidden'>
                {/* Mobile menu button*/}
                <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-darkgreen hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <HiX className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <HiMenu className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
              <div className='flex-1 flex items-center justify-center md:items-stretch md:justify-start'>
                <div className='flex-shrink-0 flex items-center'>
                  <img
                    className='block lg:hidden h-16 moblogo'
                    src='/assets/images/brands/logo.png'
                    alt='NCF Nigeria'
                  />
                  <img
                    className='hidden lg:block h-16 brandlogo mr-8'
                    src='/assets/images/brands/logo.png'
                    alt='NCF Nigeria'
                  />
                </div>


                <div className='hidden md:block sm:ml-6'>
                  <div className='flex py-5 space-x-4'>
                    {navigation.map((nav) =>
                    // Links with Dropdowns
                      nav.sublist && nav.sublist.length > 0 ? (
                        <div key={nav.name} className='group inline-block'>
                          <button className='outline-none focus:outline-none text-grey-300 px-3 py-2 rounded-sm flex items-center'>
                            <span className='pr-1 text-xs navlink flex-1'>
                              <a className="text-darkgreen navlink">{nav.name}</a>
                            </span>
                            <span>
                              <HiOutlineChevronDown className='h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out' />
                            </span>
                          </button>
                          <ul
                            className='bg-darkgreen text-white rounded-sm transform scale-0 group-hover:scale-100 absolute 
											transition duration-150 ease-in-out origin-top min-w-32 outline-none focus:outline-none'
                          >
                            {nav.sublist.map((list) =>
                              list.sublist ? (
                                <li
                                  key={list.name}
                                  className='rounded-sm relative px-3 py-1'
                                >
                                  <button className='w-full text-left flex items-center outline-none focus:outline-none'>
                                    <span className='pr-1 flex-1'>
                                      {/* Habitat */}
                                      <a className="text-white navlink">{list.name}</a>
                                    </span>
                                    <span className='mr-auto'>
                                      <HiOutlineChevronDown className='h-4 w-4 transition duration-150 ease-in-out' />
                                    </span>
                                  </button>
                                  {list.sublist && (
                                    <ul className='bg-darkgreen rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32'>
                                      {list.sublist.map((next) =>
                                        next.sublist ? (
                                          <li
                                            key={next.name}
                                            className='rounded-sm relative px-3 py-1 navlink'
                                          >
                                            <button className='w-full text-left flex items-center outline-none focus:outline-none'>
                                              <span className='pr-1 flex-1'>
                                                <a className="navlink" href={next.href}>
                                                  H1
                                                </a>
                                              </span>
                                              <span className='mr-auto'>
                                                <HiOutlineChevronDown className='h-4 w-4 transition duration-150 ease-in-out' />
                                              </span>
                                            </button>
                                            {next.sublist && (
                                              <ul className='bg-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32'>
                                                {next.sublist.map((sub3) => (
                                                  <li
                                                    key={sub3.name}
                                                    className='px-3 py-1 navlink'
                                                  >
                                                    {sub3.name}
                                                  </li>
                                                ))}
                                              </ul>
                                            )}
                                          </li>
                                        ) : (
                                          <li
                                            key={next.name}
                                            className='px-3 py-1'
                                          >
                                            <a className="text-white navlink" href={next.href}>{next.name}</a>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                                </li>
                              ) : (
                                <li
                                  key={list.name}
                                  className='rounded-sm px-3 py-1'
                                >
                                  <button
                                    component={Link}
                                    to='/about-us'
                                    className='w-full text-left flex items-center outline-none focus:outline-none'
                                  >
                                    <span className='pr-1 flex-1'>
                                      <a className="text-white navlink" href={list.href}>{list.name}</a>
                                    </span>
                                  </button>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      ) : (
                        <a
                          key={nav.name}
                          href={nav.href}
                          className={classNames(
                            nav.current
                              ? 'bg-gray-800 text-dark'
                              : 'text-dark-300',
                            'px-3 py-2 rounded-md text-darkgreen text-xs font-medium navlink'
                          )}
                          aria-current={nav.current ? 'page' : undefined}
                        >
                          {nav.name}
                        </a>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                <a
                  href='/our-centers'
                  className='shadow-lg rounded-full bg-white font-bold py-2 px-4 flex items-center outline-none no-underline focus:outline-none'
                >
                  <span className='flex-1 text-xs  text-darkgreen'>OUR CENTERS</span>
                </a>
                {/* Display Profile dropdown if user is logged in */}
                {loggedInUser ? 
                // Profile Dropdown
                <Menu as='div' className='ml-3 relative'>
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className='bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
                          <span className='sr-only'>Open user menu</span>
                          <img
                            className='h-8 w-8 rounded-full'
                            src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                            alt=''
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items
                          static
                          className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                        >
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href='/user'
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block my-0 px-4 py-2 text-sm text-gray-700 no-underline	'
                                )}
                              >
                                Your Profile
                              </a>
                            )}
                          </Menu.Item>
                          {/* <Menu.Item>
                            {({ active }) => (
                              <a
                                href='/'
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block my-0 px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Settings
                              </a>
                            )}
                          </Menu.Item> */}
                          <Menu.Item>
                            {({ active }) => (
                              <button onClick={logoutHandler}
                                href='/'
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block my-0 px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
                // Profile Dropdown End
                :
                  <a href="/signin " className= 'px-3 py-2 rounded-md text-darkgreen text-xs navlink font-bold'>
                  Sign in 
                  </a>
                  }
                

                {/* Profile dropdown */}
                {/* <Menu as='div' className='ml-3 relative'>
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className='bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
                          <span className='sr-only'>Open user menu</span>
                          <img
                            className='h-8 w-8 rounded-full'
                            src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                            alt=''
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items
                          static
                          className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                        >
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href='/'
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block my-0 px-4 py-2 text-sm text-darkgreen'
                                )}
                              >
                                Your Profile
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href='/'
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block my-0 px-4 py-2 text-sm text-darkgreen'
                                )}
                              >
                                Settings
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href='/'
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block my-0 px-4 py-2 text-sm text-darkgreen'
                                )}
                              >
                                Sign out
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu> */}
              </div>
            </div>
          </div>













          {/* MOBILE MENU VIEW */}

          <Disclosure.Panel className='md:hidden'>
            <div className='px-2 pt-2 pb-3 space-y-1'>
              {navigation.map((item) => (
                item.sublist && item.sublist.length > 0 ? (
                  <Menu>
                    <div key={item.name} className='group block'>
                    <Menu.Button className='outline-none focus:outline-none text-grey-300 px-3 py-2 rounded-sm flex items-center'>
                      <span className='pr-1 text-sm font-medium flex-1'>
                        <a className="text-darkgreen">{item.name}</a>
                      </span>
                      <span>
                        <HiOutlineChevronDown className='h-4 w-4 transform group-click:-rotate-180 transition duration-150 ease-in-out' />
                      </span>
                    </Menu.Button>
                    <Transition
                    as={Fragment}
                    enter="transition ease-in-out duration-[400ms]"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items>
                        <Menu.Item>
                        <ul
                          className='bg-darkgreen text-white rounded-sm relative 
                          transition duration-150 ease-in-out origin-top min-w-32 outline-none focus:outline-none'
                        >
                          {item.sublist.map((list) =>
                            list.sublist ? (
                              // habitat
                              <Menu>
                                <li
                                key={list.name}
                                className='rounded-sm relative px-3 py-1'
                              >
                                <Menu.Button className='inline-flex text-left w-full  py-2  font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                                  <span className='pr-1 flex-1'>
                                    <a className="text-white">{list.name}</a>
                                  </span>
                                  <span>
                                    <HiOutlineChevronDown
                                      className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Menu.Button>
                                {list.sublist && (
                                  <Transition
                                  as={Fragment}
                                  enter="transition ease-in-out duration-[400ms]"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items>
                                    <ul className='bg-darkgreen rounded-sm absolute left-20 w-56 origin-top-left'>
                                    {list.sublist.map((next) =>(
                                        <Menu.Item>
                                          <li
                                          key={next.name}
                                          className='px-3 py-1'
                                        >
                                          <a className="text-white" href={next.href}>{next.name}</a>
                                        </li>
                                        </Menu.Item>
                                      )
                                    )}
                                  </ul>
                                  </Menu.Items>
                                  </Transition>
                                )}
                              </li>
                              </Menu>
                            ) : (

                              //Drop down content
                              
                                <li
                                  key={list.name}
                                  className='rounded-sm px-3 py-1'
                                >
                                  <button
                                    component={Link}
                                    to='/about-us'
                                    className='w-full text-left flex items-center outline-none focus:outline-none'
                                  >
                                    <span className='py-1 flex-1'>
                                      <a className="text-white" href={list.href}>{list.name}</a>
                                    </span>
                                  </button>
                                </li>
                                
                              
                            )
                          )}
                        </ul>
                        </Menu.Item>
                        </Menu.Items>
                    </Transition>
                  </div>
                  </Menu>
                ) : (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-gray-800 text-dark'
                        : 'text-dark-300',
                      'px-3 py-2 rounded-md text-sm font-medium text-darkgreen block'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                )
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default Appbar;
