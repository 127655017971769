import React from 'react'
import "./Content.css"

function AppContent({children}) {
	return (
        <main className="content">
            {children}
        </main>
	)
}

export default AppContent
