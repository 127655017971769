import React from 'react';
import { FaFacebookSquare, FaTwitter, FaYoutube, FaInstagram } from "react-icons/fa"
import "./TopBar.css"
function TopHeader() {
	return (
        <nav className="w-full topbar text-white bg-green py-2 px-2 sm:px-6 lg:px-4" role="navigation" aria-label="main navigation">
            <div className="max-w-7xl mx-auto">
                <div className="flex md:flex-row flex-col justify-between items-center">
                    <div>
                        <p className="text-sm text-gray-300">For nature....For people.....For Nigeria</p>
                    </div>

                    <div className="flex items-center space-x-8">
                        <div className="flex space-x-4">
                            <a href="https://www.facebook.com/NCFNigeria/" className="text-white">
                                <span><FaFacebookSquare /></span>
                            </a>

                            <a href="https://twitter.com/ncfnigeria" className="text-white">
                                <span><FaTwitter /></span>
                            </a>

                            <a href="https://www.youtube.com/user/NCF1980" className="text-white">
                                <span><FaYoutube/></span>
                            </a>

                            <a href="https://www.instagram.com/ncfnigeria/" className="text-white">
                                <span><FaInstagram /></span>
                            </a>
                        </div>

                        <div className="">
                            <span className="text-sm text-gray-300">
                                <strong>
                                    Call: +2349034895750
                                </strong>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
	)
}

export default TopHeader
