import React from 'react';

export const BlogConfig = {
  routes: [
    {
      path: '/blog',
      exact: true,
      component: React.lazy(() => import('./')),
    },
    {
      path: '/blog/tag',
      exact: true,
      component: React.lazy(() => import('./TagPage')),
    },

    {
      path: '/blog/post',
      exact: true,
      component: React.lazy(() => import('./Single')),
    },
    {
      path: '/blog/search',
      exact: true,
      component: React.lazy(() => import('./Search')),
    }
  ],
};
