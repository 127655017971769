
import {SERVER_URL, CLIENT_URL} from '../config/server';

const logoutAction = () => {
    fetch(`${SERVER_URL}/auth/logout`)
    .then(res => res.json())
    .then(json => {
      console.log(json.message, 'json')
      if(json.status === 200){
        localStorage.clear();
          window.location.href = CLIENT_URL;
      }else{
          console.log("Error occured during logout");
      }
  });
  }

 export default logoutAction
