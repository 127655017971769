import React from 'react';
import {
  FaFacebookSquare,
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaEnvelope,
} from 'react-icons/fa';
import { ImLocation } from 'react-icons/im';
import { HiOutlinePhone } from 'react-icons/hi';
import { InnerFooter } from './styles';
import "./footer.css"
import newsletterAction from '../../../actions/newsletter'
import {UncontrolledAlert ,Alert} from 'reactstrap'

function AppFooter() {
  const newsletterSignUp = (e) =>{
    e.preventDefault();
    const email = e.target.firstChild.firstChild.value;
    const newsletterData = {email};
    newsletterAction(newsletterData)
  }
  const error = localStorage.getItem('login-error');
  const success = localStorage.getItem('login-success');
  setTimeout(function() {
    localStorage.removeItem('login-error');
  }, 15000)
  setTimeout(function() {
    localStorage.removeItem('login-success');
  }, 15000)
  return (
    <div className='w-full bg-green-dark z-index-2 text-gray-300 text-sm pt-40 mt-40'>
      <img
        src='/assets/images/bg/white-bg.png'
        alt=''
        className='w-full relative sm-hidden -top-25em mb-15em '
      />
      <InnerFooter>
        <div className='max-w-7xl mx-auto md:px-4 px-8 relative mt-25em foot'>
          <div className='grid md:grid-cols-4 grid-cols-1 md:gap-8 gap-y-8'>
            <div className='block'>
              <h2 className='text-base font-bold text-white mb-3'>ABOUT US</h2>
              <p className=''>
                The Nigerian Conservation Foundation (NCF) is the premier Non
                Governmental Organisation (NGO) dedicated to nature conservation
                and sustainable development in Nigeria.
              </p>
              <h2 className='text-base font-bold text-white my-4'>
                NCF PARTNERS
              </h2>
              <ul className='flex space-x-2'>
                <li>
                  <img
                    className='w-16'
                    src='/assets/images/partners/birdlife.svg'
                    alt=''
                  />
                </li>
                <li>
                  <img
                    className='w-16'
                    src='/assets/images/partners/rspb.jpeg'
                    alt=''
                  />
                </li>
                <li>
                  <img
                    className='w-16'
                    src='/assets/images/partners/wwf.svg'
                    alt=''
                  />
                </li>
                <li>
                  <img
                    className='w-16'
                    src='/assets/images/partners/iucn.svg'
                    alt=''
                  />
                </li>
              </ul>
              <ul className='flex space-x-2 mt-3 mb-3'>
                <li>
                  <img
                    className='w-40'
                    src='/assets/images/partners/IUCNEU.png'
                    alt=''
                  />
                </li>
              </ul>
            </div>
            <div className='block'>
              <h2 className='text-base font-bold text-white mb-3'>OUR WORK</h2>
              <aside className=''>
                <ul className=''>
                  <li>
                    <a className='text-gray-300 hover:text-white' href='/climate-change'>
                      Climate Change
                    </a>
                  </li>
                  <li>
                    <a className='text-gray-300 hover:text-white' href='/species'>
                      Species Conservation
                    </a>
                  </li>
                  <li>
                    <a className='text-gray-300 hover:text-white' href='/policy-advocacy'>
                      Policy & Advocacy
                    </a>
                  </li>
                  <li>
                    <a className='text-gray-300 hover:text-white' href='/environmental-education'>
                      Environmental Education
                    </a>
                  </li>
                  <li>
                    <a className='text-gray-300 hover:text-white' href='/marine-coastline'>
                      Marine Coastal & Wetland
                    </a>
                  </li>
                  <li>
                    <a className='text-gray-300 hover:text-white' href='/our-work'>
                      Forest
                    </a>
                  </li>
                </ul>
              </aside>
              <h2 className='text-base font-bold text-white'>
                FOLLOW US ON
              </h2>
              <div className='flex items-center justify-start space-x-4'>
                <a href='https://www.facebook.com/NCFNigeria/' className='text-gray-100 hover:text-white'>
                  <FaFacebookSquare size='20' />
                </a>
                <a href='https://twitter.com/ncfnigeria' className='text-gray-100 hover:text-white'>
                  <FaTwitter size='20' />
                </a>
                <a href='https://www.youtube.com/user/NCF1980' className='text-gray-100 hover:text-white'>
                  <FaYoutube size='20' />
                </a>
                <a href='https://www.instagram.com/ncfnigeria/' className='text-gray-100 hover:text-white'>
                  <FaInstagram size='20' />
                </a>
              </div>
            </div>
            <div className='block'>
              <h2 className='text-base font-bold text-white mb-3'>
                GET INVOLVED
              </h2>
              <aside className=''>
                <ul className=''>
                  <li>
                    <a className='text-gray-300 hover:text-white' href='/get-involved'>
                      Membership
                    </a>
                  </li>
                  <li>
                    <a className='text-gray-300 hover:text-white' href='/donate'>
                      Donate
                    </a>
                  </li>
                  <li>
                    <a className='text-gray-300 hover:text-white' href='/volunteer'>
                      Volunteer
                    </a>
                  </li>
                </ul>
              </aside>
            </div>
            <div className='block'>
            {error ? 
              <UncontrolledAlert color="danger">
                {error}
              </UncontrolledAlert>
              
            : null }
            {success ? 
              <UncontrolledAlert color="success">
                {success}
              </UncontrolledAlert>
              
            : null } 
              <h2 className='text-base font-bold text-white mb-3'>
                SIGN UP FOR NEWSLETTER
              </h2>
              <p>
                Get latest information on our events, programmes and special
                membership offers.
              </p>
              <form onSubmit={newsletterSignUp}>
              <div className='my-2 flex'>
                <input
                  className='border-green-300 p-3 focus:outline-none focus:ring-1 focus:ring-green-light focus:ring-opacity-50 flex-1 block w-full'
                  type='text'
                  placeholder='Enter your email address'
                />
                <button type="submit" className='inline-flex items-center px-4 border border-l-0 border-green-light bg-green text-white text-sm'>
                  <FaEnvelope color='#fff' size='18' />
                </button>
              </div>
              </form>

              <h2 className='text-base font-bold text-white my-2'>
                STAY IN TOUCH
              </h2>
              <aside className=''>
                <dl className='space-y-1'>
                  <div className='flex items-start space-x-2'>
                    <dt className='mt-1'>
                      <ImLocation className='text-gray-100 flex-none w-5 h-full' />{' '}
                    </dt>
                    <dd>
                      <span>
                        NCF Headquarters, Km 19, Lekki-Epe Expressway, Lekki.
                        Lagos. Nigeria
                      </span>
                    </dd>
                  </div>
                  <div className='flex items-start space-x-2'>
                    <dt className='sm:mt-0 mt-0'>
                      <HiOutlinePhone className='text-gray-100 flex-none w-5 h-full' />{' '}
                    </dt>
                    <dd>
                      <span>+2349034895750 +2349091476149 +2349065460479</span>
                    </dd>
                  </div>
                  <div className='flex items-center space-x-2'>
                    <dt className='mt-0'>
                      <FaEnvelope className='text-gray-100 flex-none w-5 h-full' />
                    </dt>
                    <dd>
                      <span>info@ncfnigeria.org</span>
                    </dd>
                  </div>
                </dl>
              </aside>
            </div>
          </div>

          <div className='content text-sm '>
            <p>© 2020 Nigerian Conservation Foundation. All Right Reserved</p>
          </div>
        </div>
        <div className='text-center p-8  text-white space-x-2'>
            <h5 className="text-white text-sm">Designed by <a className="text-white no-underline" href="https://optisoft.ng">Optisoft</a></h5>
        </div>
      </InnerFooter>
    </div>
  );
}

export default AppFooter;
