import Root from '../layout';
import { NFCUtils } from '@nfc';
import { HomeConfig } from '../main/home/homeConfig';
import { AboutUsConfig } from '../main/about-us/aboutUsConfig';
import { ContactUsConfig } from '../main/contact/contactConfig';
import { OurWorkConfig } from '../main/our-work/ourWorkConfig';
import { ShopConfig } from '../main/shop/shopConfig';
import { CartConfig } from '../main/cart/cartConfig';
import { LibraryConfig } from '../main/library/libraryConfig';
import { BlogConfig } from '../main/blog/blogConfig';
import { DonateConfig } from '../main/donate/donateConfig';
import { GetInvolvedConfig } from '../main/get-involved/getInvolvedConfig';
import { SupportNatureConfig } from '../main/support-nature/SupportNatureConfig';
import { UserConfig } from '../main/User/userConfig';
import { OurCentersConfig } from '../main/our-centers/centersConfig';

const routeConfigs = [
  HomeConfig,
  AboutUsConfig,
  ContactUsConfig,
  OurWorkConfig,
  ShopConfig,
  CartConfig,
  LibraryConfig,
  GetInvolvedConfig,
  SupportNatureConfig,
  BlogConfig,
  DonateConfig,
  UserConfig,
  OurCentersConfig,
];

const routes = [
  {
    component: Root,
    routes: [...NFCUtils.generateRoutesFromConfigs(routeConfigs)],
  },
];

export default routes;
