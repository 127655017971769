import React from 'react';

export const SupportNatureConfig = {
  routes: [
    {
      path: '/support-nature',
      exact: true,
      component: React.lazy(() => import('.')),
    }
  ],
};
