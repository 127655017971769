import React from 'react';

export const GetInvolvedConfig = {
  routes: [
    {
      path: '/corporate-membership',
      exact: true,
      component: React.lazy(() => import('./Corporate')),
    },
    {
      path: '/individual-membership',
      exact: true,
      component: React.lazy(() => import('./Individual')),
    },
    {
      path: '/membership/corporate',
      exact: true,
      component: React.lazy(() => import('./Corporate-membership')),
    },
    {
      path: '/membership/individual',
      exact: true,
      component: React.lazy(() => import('./Individual-membership')),
    },
    {
      path: '/volunteer',
      exact: true,
      component: React.lazy(() => import('./Volunteer')),
    },
    {
      path: '/bird-club',
      exact: true,
      component: React.lazy(() => import('./BirdClub')),
    },
    {
      path: '/events',
      exact: true,
      component: React.lazy(() => import('./Events')),
    },
  ],
};
