import React from 'react';

export const ShopConfig = {
  routes: [
    {
      path: '/shop',
      exact: true,
      component: React.lazy(() => import('.')),
    },
    {
      path: '/shop/product',
      exact: true,
      component: React.lazy(() => import('./Product')),
    },
    {
      path: '/shop/category',
      exact: true,
      component: React.lazy(() => import('./ByCategory')),
    },
    {
      path: '/shop/search',
      exact: true,
      component: React.lazy(() => import('./Search')),
    },
    {
      path: '/shop/:id',
      exact: true,
      component: React.lazy(() => import('./shop')),
    }
  ],
};
