import React from "react"
import { Router, Route, Switch } from "react-router-dom"
import {renderRoutes} from "react-router-config"
import history from "./history"
// import AppLayout from "./layout"
import routes from "./config/routeConfig"
import "antd/dist/antd.css";

// const Home = React.lazy(() => import("./main/home"))
// const AboutUs = React.lazy(() => import("./main/about-us"))
// const OurWork = React.lazy(() => import("./main/our-work"))
// const Shop = React.lazy(() => import("./main/shop"))
const SignUp = React.lazy(() => import("./main/SignUp"))
const SignIn = React.lazy(() => import("./main/SignIn"))
const Forgot = React.lazy(() => import("./main/Forgot"))
const Reset = React.lazy(() => import("./main/Forgot/Reset"))
const Activate = React.lazy(() => import("./main/SignUp/Activate"))
  
function App() {
  return (
    <div className="App">
      <React.Suspense fallback={null}>
        <Router history={history}>
        {history.location.pathname === "/signup" || history.location.pathname === "/signin" 
        || history.location.pathname === "/forgot-password" || history.location.pathname === "/reset-password"
        || history.location.pathname === "/activate-account" ? (
          <Switch>
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/forgot-password" component={Forgot} />
            <Route exact path="/reset-password" component={Reset} />
            <Route exact path="/activate-account" component={Activate} />
          </Switch>
        ) :
        renderRoutes(routes)
        
        }   
        </Router>
      </React.Suspense>
    </div>
  );
}

export default App;
